import { combineReducers } from 'redux';

import settingsReducer from './tager/settings';
import seoReducer from './tager/seo';
import bannersReducer from './tager/banners';
import pagesReducer from './tager/pages';
import menusReducer from './tager/menus';
import categoryReducer from './category';
import productReducer from './product';
import cartReducer from './cart';
import platformsReducer from './platforms';
import filterReducer from './filter';
import searchReducer from './search';
import catalogReducer from './catalog';

const tagerReducer = combineReducers({
  banners: bannersReducer,
  menus: menusReducer,
  pages: pagesReducer,
  seo: seoReducer,
  settings: settingsReducer,
});

const rootReducer = combineReducers({
  tager: tagerReducer,
  category: categoryReducer,
  product: productReducer,
  cart: cartReducer,
  platforms: platformsReducer,
  filter: filterReducer,
  search: searchReducer,
  catalog: catalogReducer,
});

export default rootReducer;
