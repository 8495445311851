import React from 'react';
import styled, { css } from 'styled-components';

import { Picture } from '@/components/Picture';
import { CategoryModel } from '@/typings/model';

interface Props extends CategoryModel {
  isSelected: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

function Category({
  name,
  urlAlias,
  icon,
  iconActive,
  isSelected,
  onClick,
}: Props) {
  return (
    <Component
      href={urlAlias ?? undefined}
      isIcon={Boolean(icon)}
      isSelected={isSelected}
      onClick={onClick}
    >
      {icon && <Icon src={icon} alt={name ?? ''} isSelected={!isSelected} />}
      {iconActive && (
        <Icon src={iconActive} alt={name ?? ''} isSelected={isSelected} />
      )}
      <Label>{name}</Label>
    </Component>
  );
}

export default Category;

const Component = styled.a<{ isIcon: boolean; isSelected: boolean }>`
  display: flex;
  align-items: center;

  padding: 10px 15px;

  ${({ isIcon }) =>
    isIcon &&
    css`
      padding-left: 10px;
    `};

  color: #0d0735;
  background: #e2e7f1;

  border-radius: 8px;

  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #d2d9e9;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: #e2e7f1;
      background-color: #0d0735;
      pointer-events: none;

      &:hover {
        background-color: #0d0735;
      }
    `};
`;

const Icon = styled(Picture)<{ isSelected: boolean }>`
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')} !important;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  margin-right: 7px;
`;

const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 900;
  font-size: 16px;
  line-height: 19px;

  white-space: nowrap; /* Запрещаем перенос текста */
`;
