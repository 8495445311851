import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LiveSearchModel } from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import { fetchLiveSearch } from '@/services/requests';

interface State {
  searchQuery: string;
  liveSearch: {
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    data: Array<LiveSearchModel>;
  };
}

const initialState: State = {
  searchQuery: '',
  liveSearch: {
    status: 'idle',
    data: [],
  },
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },

    setLiveSearchPending: (state) => {
      state.liveSearch.status = 'pending';
    },
    setLiveSearchSucceeded: (
      state,
      action: PayloadAction<Array<LiveSearchModel>>
    ) => {
      state.liveSearch.status = 'succeeded';
      state.liveSearch.data = action.payload;
    },
    setLiveSearchFailed: (state) => {
      state.liveSearch.status = 'failed';
    },
  },
});

export default slice.reducer;

export const {
  setSearchQuery,

  setLiveSearchPending,
  setLiveSearchSucceeded,
  setLiveSearchFailed,
} = slice.actions;

/** Thunks **/

export function getLiveSearchThunk(params: {
  query: string;
  category?: number;
  signal: AbortSignal;
}): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(setLiveSearchPending());
    try {
      const response = await fetchLiveSearch(params);
      dispatch(setLiveSearchSucceeded(response.data));
    } catch (error) {
      if (error.name !== 'AbortError') {
        dispatch(setLiveSearchFailed());
      }
    }
  };
}

/** Selectors **/

export function getLiveSearchList(state: AppState): Array<LiveSearchModel> {
  return state.search.liveSearch.data;
}
