import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OptionType } from '@/typings/common';
import { AppState, AppThunk } from '@/store/store';
import {
  CategoryByUrlAliasModel,
  CategoryModel,
  FeatureModel,
  MetaModel,
  PlatformModel,
  ProductFullModel,
  ProductModel,
  SubcategoryModel,
} from '@/typings/model';
import {
  fetchCategories,
  fetchCategoryByUrlAlias,
  fetchPlatforms,
  fetchProductByCategoryAliasAndProductAlias,
  fetchProducts,
} from '@/services/requests';
import { defaultCategory } from '@/modules/Catalog/components/Content/components/Categories';

interface State {
  platforms: {
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    data: Array<PlatformModel>;
  };
  subcategories: Record<
    string,
    {
      status: 'idle' | 'pending' | 'succeeded' | 'failed';
      data: Array<SubcategoryModel>;
    }
  >;
  features: Record<
    string,
    {
      status: 'idle' | 'pending' | 'succeeded' | 'failed';
      data: Array<FeatureModel>;
    }
  >;
  categories: {
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    data: Array<CategoryModel>;
  };
  categoriesByUrlAlias: Record<
    string,
    {
      status: 'idle' | 'pending' | 'succeeded' | 'failed';
      data: CategoryByUrlAliasModel;
    }
  >;
  products: {
    status: 'idle' | 'pending' | 'succeeded' | 'failed';
    data: Array<ProductModel>;
    meta?: MetaModel;
  };
  product: Record<
    string,
    {
      status: 'idle' | 'pending' | 'succeeded' | 'failed';
      data: ProductFullModel;
    }
  >;
  selectedCategory: string;
  selectedTechnologies: Array<OptionType>;
  selectedPlatforms: Record<string, Array<OptionType>>;
  selectedSubcategories: Record<string, Array<OptionType>>;
  selectedFeatures: Record<string, Array<OptionType>>;
  pageNumber: number;
}

const initialState: State = {
  platforms: {
    status: 'idle',
    data: [],
  },
  subcategories: {},
  features: {},
  categories: {
    status: 'idle',
    data: [],
  },
  categoriesByUrlAlias: {},
  products: {
    status: 'idle',
    data: [],
  },
  product: {},
  selectedCategory: defaultCategory.urlAlias,
  selectedTechnologies: [],
  selectedPlatforms: {},
  selectedSubcategories: {},
  selectedFeatures: {},
  pageNumber: 1,
};

const slice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    /** Platforms **/
    setPlatformsPending: (state) => {
      state.platforms.status = 'pending';
    },
    setPlatformsSucceeded: (
      state,
      action: PayloadAction<Array<PlatformModel>>
    ) => {
      state.platforms.status = 'succeeded';
      state.platforms.data = action.payload;
    },
    setPlatformsFailed: (state) => {
      state.platforms.status = 'failed';
    },

    /** Categories **/
    setCategoriesPending: (state) => {
      state.categories.status = 'pending';
    },
    setCategoriesSucceeded: (
      state,
      action: PayloadAction<Array<CategoryModel>>
    ) => {
      state.categories.status = 'succeeded';
      state.categories.data = action.payload;
    },
    setCategoriesFailed: (state) => {
      state.categories.status = 'failed';
    },

    /** Category by url alias **/
    setCategoryByUrlAliasPending: (
      state,
      action: PayloadAction<{
        key: string;
      }>
    ) => {
      state.categoriesByUrlAlias[action.payload.key] = {
        ...state.categoriesByUrlAlias[action.payload.key],
        status: 'pending',
      };

      state.subcategories[action.payload.key] = {
        ...state.subcategories[action.payload.key],
        status: 'pending',
      };

      state.features[action.payload.key] = {
        ...state.features[action.payload.key],
        status: 'pending',
      };
    },
    setCategoryByUrlAliasSucceeded: (
      state,
      action: PayloadAction<{
        key: string;
        data: CategoryByUrlAliasModel;
      }>
    ) => {
      state.categoriesByUrlAlias[action.payload.key] = {
        status: 'succeeded',
        data: action.payload.data,
      };

      state.subcategories[action.payload.key] = {
        status: 'succeeded',
        data: action.payload.data.subcategories,
      };

      state.features[action.payload.key] = {
        status: 'succeeded',
        data: action.payload.data.features,
      };
    },
    setCategoryByUrlAliasFailed: (
      state,
      action: PayloadAction<{
        key: string;
      }>
    ) => {
      state.categoriesByUrlAlias[action.payload.key] = {
        ...state.categoriesByUrlAlias[action.payload.key],
        status: 'failed',
      };

      state.subcategories[action.payload.key] = {
        ...state.subcategories[action.payload.key],
        status: 'failed',
      };

      state.features[action.payload.key] = {
        ...state.features[action.payload.key],
        status: 'failed',
      };
    },

    /** Products **/
    setProductsPending: (state) => {
      state.products.status = 'pending';
    },
    setProductsSucceeded: (
      state,
      action: PayloadAction<{ data: Array<ProductModel>; meta?: MetaModel }>
    ) => {
      state.products.status = 'succeeded';
      state.products.data = action.payload.data;
      state.products.meta = action.payload.meta;
    },
    setProductsFailed: (state) => {
      state.products.status = 'failed';
    },

    /** Product **/
    setProductPending: (state, action: PayloadAction<{ key: string }>) => {
      state.product[action.payload.key] = {
        ...state.product[action.payload.key],
        status: 'pending',
      };
    },
    setProductSucceeded: (
      state,
      action: PayloadAction<{ key: string; data: ProductFullModel }>
    ) => {
      state.product[action.payload.key] = {
        status: 'succeeded',
        data: action.payload.data,
      };
    },
    setProductFailed: (state, action: PayloadAction<{ key: string }>) => {
      state.product[action.payload.key] = {
        ...state.product[action.payload.key],
        status: 'failed',
      };
    },

    /** SelectedCategory **/
    setSelectedCategory: (state, action: PayloadAction<string>) => {
      state.selectedCategory = action.payload;
    },

    /** Filters **/
    setSelectedTechnologies: (
      state,
      action: PayloadAction<Array<OptionType>>
    ) => {
      state.selectedTechnologies = action.payload;
    },
    setSelectedPlatforms: (
      state,
      action: PayloadAction<{ categoryAlias: string; data: Array<OptionType> }>
    ) => {
      state.selectedPlatforms[action.payload.categoryAlias] =
        action.payload.data;
    },
    setSelectedSubcategories: (
      state,
      action: PayloadAction<{ key: string; data: Array<OptionType> }>
    ) => {
      state.selectedSubcategories[action.payload.key] = action.payload.data;
    },
    setSelectedFeatures: (
      state,
      action: PayloadAction<{ key: string; data: Array<OptionType> }>
    ) => {
      state.selectedFeatures[action.payload.key] = action.payload.data;
    },

    /** Pagination **/
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setPlatformsPending,
  setPlatformsSucceeded,
  setPlatformsFailed,

  setCategoriesPending,
  setCategoriesSucceeded,
  setCategoriesFailed,

  setCategoryByUrlAliasPending,
  setCategoryByUrlAliasSucceeded,
  setCategoryByUrlAliasFailed,

  setProductsPending,
  setProductsSucceeded,
  setProductsFailed,

  setProductPending,
  setProductSucceeded,
  setProductFailed,

  setSelectedCategory,
  setSelectedTechnologies,
  setSelectedPlatforms,
  setSelectedSubcategories,
  setSelectedFeatures,

  setPageNumber,
} = slice.actions;

/**** Thunks ****/

/** Platforms **/
export function getPlatformsThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const platformList = getPlatformList(getState());
    if (!options?.shouldInvalidate && platformList.length > 0) {
      return;
    }
    dispatch(setPlatformsPending());
    try {
      const response = await fetchPlatforms();
      dispatch(setPlatformsSucceeded(response));
    } catch (error) {
      dispatch(setPlatformsFailed());
    }
  };
}

/** Categories **/
export function getCategoriesThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const categoryList = getCategoryList(getState());
    if (!options?.shouldInvalidate && categoryList.length > 0) {
      return;
    }
    dispatch(setCategoriesPending());
    try {
      const response = await fetchCategories();
      dispatch(setCategoriesSucceeded(response.data));
    } catch (error) {
      dispatch(setCategoriesFailed());
    }
  };
}

export function getCategoryByAliasThunk(
  categoryAlias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const category = getCategoryByAliasFormCategoriesByAlias(
      getState(),
      categoryAlias
    );
    if (!options?.shouldInvalidate && category) {
      return;
    }
    dispatch(setCategoryByUrlAliasPending({ key: categoryAlias }));
    try {
      const response = await fetchCategoryByUrlAlias(categoryAlias);
      dispatch(
        setCategoryByUrlAliasSucceeded({
          key: categoryAlias,
          data: response.data,
        })
      );
    } catch (error) {
      dispatch(setCategoryByUrlAliasFailed({ key: categoryAlias }));
    }
  };
}

/** Products **/
export function getProductsThunk(
  {
    pageSize = 20,
    pageNumber = 1,
    ...other
  }: {
    searchQuery?: string;
    pageSize?: number;
    pageNumber?: number;
    categoryId?: number;
    categoryAlias?: string;
    videoFaceAr?: string;
    videoPostProcessing?: string;
    platforms?: Array<string>;
    subcategories?: Array<string>;
    features?: Array<string>;
  },
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const productList = getProductList(getState());
    if (!options?.shouldInvalidate && productList.length > 0) {
      return;
    }
    dispatch(setProductsPending());
    try {
      const response = await fetchProducts({ pageSize, pageNumber, ...other });
      dispatch(setProductsSucceeded(response));
    } catch (error) {
      dispatch(setProductsFailed());
    }
  };
}

/** Product **/

export function getProductByCategoryAliasAndProductAliasThunk(
  params: {
    categoryAlias: string;
    productAlias: string;
  },
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const product = getProductByCategoryAliasAndProductAlias(
      getState(),
      params
    );
    if (!options?.shouldInvalidate && product) {
      return;
    }
    const key = `${params.categoryAlias}/${params.productAlias}`;
    dispatch(setProductPending({ key }));
    try {
      const response = await fetchProductByCategoryAliasAndProductAlias(params);
      dispatch(setProductSucceeded({ key, data: response.data }));
    } catch (error) {
      dispatch(setProductFailed({ key }));
    }
  };
}

/**** Selectors ****/

/** Platforms **/

export function getPlatformList(state: AppState): State['platforms']['data'] {
  return state.catalog.platforms.data;
}

/** Categories **/

export function getCategoryList(state: AppState): State['categories']['data'] {
  return state.catalog.categories.data;
}

export function getCategoryIdByAlias(
  state: AppState,
  categoryAlias: string
): number | undefined {
  const foundCategory = state.catalog.categories.data.find(
    (category) => category.urlAlias === categoryAlias
  );
  return foundCategory?.id;
}

/** Categories By Alias **/

export function getCategoryByAliasFormCategoriesByAlias(
  state: AppState,
  categoryAlias: string
): CategoryModel | undefined {
  const foundCategory = state.catalog.categoriesByUrlAlias[categoryAlias];
  return foundCategory ? foundCategory.data : undefined;
}

/** Subcategories **/

export function getSubcategoryList(
  state: AppState,
  key: string
): Array<SubcategoryModel> {
  return state.catalog.subcategories[key]?.data ?? [];
}

/** Features **/

export function getFeatureList(
  state: AppState,
  key: string
): Array<FeatureModel> {
  return state.catalog.features[key]?.data ?? [];
}

/** Products **/
export function getProductList(state: AppState): State['products']['data'] {
  return state.catalog.products.data;
}

export function getProducts(state: AppState): State['products'] {
  return state.catalog.products;
}

export function getMeta(state: AppState): State['products']['meta'] {
  return state.catalog.products.meta;
}

export function getProductsStatus(
  state: AppState
): State['products']['status'] {
  return state.catalog.products.status;
}

/** Product **/

export function getProductByCategoryAliasAndProductAlias(
  state: AppState,
  {
    categoryAlias,
    productAlias,
  }: {
    categoryAlias: string;
    productAlias: string;
  }
): ProductFullModel | undefined {
  const key = `${categoryAlias}/${productAlias}`;
  const product = state.catalog.product[key];
  return product ? product.data : undefined;
}

/** Selected Category **/

export function getSelectedCategory(
  state: AppState
): State['selectedCategory'] {
  return state.catalog.selectedCategory;
}

/** Filters **/
export function getSelectedTechnologies(state: AppState): Array<OptionType> {
  return state.catalog.selectedTechnologies;
}

export function getSelectedPlatformsKeyless(
  state: AppState
): Record<string, Array<OptionType>> {
  return state.catalog.selectedPlatforms;
}

export function getSelectedPlatforms(
  state: AppState,
  categoryAlias: string
): Array<OptionType> {
  return state.catalog.selectedPlatforms[categoryAlias] ?? [];
}

export function getSelectedSubcategoriesKeyless(
  state: AppState
): Record<string, Array<OptionType>> {
  return state.catalog.selectedSubcategories;
}

export function getSelectedSubcategories(
  state: AppState,
  key: string
): Array<OptionType> {
  return state.catalog.selectedSubcategories[key] ?? [];
}

export function getSelectedFeaturesKeyless(
  state: AppState
): Record<string, Array<OptionType>> {
  return state.catalog.selectedFeatures;
}

export function getSelectedFeatures(
  state: AppState,
  key: string
): Array<OptionType> {
  return state.catalog.selectedFeatures[key] ?? [];
}

/** Pagination **/
export function getPageNumber(state: AppState): number {
  return state.catalog.pageNumber;
}
