import React, { useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  getCategoryList,
  getSelectedCategory,
  getSelectedFeaturesKeyless,
  getSelectedPlatformsKeyless,
  getSelectedSubcategoriesKeyless,
  setSelectedCategory,
} from '@/store/reducers/catalog';
import { CategoryModel } from '@/typings/model';

import Category from './components/Category';
import { defaultCategory } from './Categories.helpers';

interface Props {}

function Categories(props: Props) {
  const dispatch = useTypedDispatch();
  const router = useRouter();

  const selectedPlatforms = useTypedSelector(getSelectedPlatformsKeyless);

  const selectedSubcategories = useTypedSelector(
    getSelectedSubcategoriesKeyless
  );
  const selectedFeatures = useTypedSelector(getSelectedFeaturesKeyless);

  const categories = useTypedSelector(getCategoryList);

  const categoryList = useMemo(() => [defaultCategory, ...categories], [
    categories,
  ]);

  const selectedCategory = useTypedSelector(getSelectedCategory);

  function isSelectedCategory(category: CategoryModel): boolean {
    return selectedCategory === category.urlAlias;
  }

  function handleCategoryChange(selectedCategory: CategoryModel) {
    dispatch(
      setSelectedCategory(selectedCategory.urlAlias ?? defaultCategory.urlAlias)
    );

    const platforms = (
      selectedPlatforms[
        selectedCategory.urlAlias ?? defaultCategory.urlAlias
      ] ?? []
    ).map((selectedPlatform) => selectedPlatform.value);

    const subcategories = (
      selectedSubcategories[
        selectedCategory.urlAlias ?? defaultCategory.urlAlias ?? ''
      ] ?? []
    ).map((selectedSubcategory) => selectedSubcategory.value);

    const features = (
      selectedFeatures[
        selectedCategory.urlAlias ?? defaultCategory.urlAlias ?? ''
      ] ?? []
    ).map((selectedFeature) => selectedFeature.value);

    const query = {
      ...omit({ ...router.query, platforms, subcategories, features }, [
        'slug',
        'page',
        'query',
      ]),
    };

    router.push({ pathname: selectedCategory.urlAlias, query }, undefined, {
      shallow: false,
    });
  }

  /** Horizontally scroll **/
  const categoryListRef = useRef<HTMLUListElement | null>(null);
  const [isScrollLeft, setIsScrollLeft] = useState(false);
  const [isScrollEndLeft, setIsScrollEndLeft] = useState(false);

  function handleCategoryListScroll(event: React.UIEvent<HTMLUListElement>) {
    if (!categoryListRef.current) {
      return;
    }
    if (categoryListRef.current?.scrollLeft > 0) {
      if (!isScrollLeft) {
        setIsScrollLeft(true);
      }
    } else {
      if (isScrollLeft) {
        setIsScrollLeft(false);
      }
    }
    if (
      categoryListRef.current?.scrollWidth -
        categoryListRef.current?.offsetWidth <=
      categoryListRef.current?.scrollLeft
    ) {
      if (!isScrollEndLeft) {
        setIsScrollEndLeft(true);
      }
    } else {
      if (isScrollEndLeft) {
        setIsScrollEndLeft(false);
      }
    }
  }

  return (
    <Component isScrollLeft={isScrollLeft} isScrollEndLeft={isScrollEndLeft}>
      <CategoryList ref={categoryListRef} onScroll={handleCategoryListScroll}>
        {categoryList.map((category, index) => (
          <CategoryItem key={index}>
            <Category
              {...category}
              isSelected={isSelectedCategory(category)}
              onClick={(event) => {
                event.preventDefault();
                handleCategoryChange(category);
              }}
            />
          </CategoryItem>
        ))}
      </CategoryList>
    </Component>
  );
}

export default Categories;

const Component = styled.div<{
  isScrollLeft: boolean;
  isScrollEndLeft: boolean;
}>`
  position: relative;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);

    width: 30px;
    height: 100%;

    background: linear-gradient(
      90deg,
      rgba(242, 246, 252, 0.0001) 0%,
      #f4f6fb 100%
    );

    opacity: 0;
    visibility: hidden;

    ${({ isScrollLeft }) =>
      isScrollLeft &&
      css`
        opacity: 1;
        visibility: visible;
      `};
  }

  &::after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    width: 30px;
    height: 100%;

    background: linear-gradient(
      90deg,
      rgba(242, 246, 252, 0.0001) 0%,
      #f4f6fb 100%
    );

    opacity: 1;
    visibility: visible;

    ${({ isScrollEndLeft }) =>
      isScrollEndLeft &&
      css`
        opacity: 0;
        visibility: hidden;
      `};
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    margin-right: -4px;
    margin-bottom: -4px;

    &::before,
    &::after {
      display: none;
    }
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const CategoryList = styled.ul`
  display: flex;
  align-items: center;

  overflow-y: hidden;
  overflow-x: auto;

  // Hide Scrollbars But Keep Functionality
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    flex-wrap: wrap;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;

const CategoryItem = styled.li`
  margin-right: 4px;

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    margin-bottom: 4px;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1400px) {
  }
`;
