import { IncomingMessage } from 'http';

import React from 'react';

import { cookie, isNotNullish, RequestError } from '@tager/web-core';

import ErrorPage from '@/pages/_error';
import { ProductCart } from '@/typings/common';

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';

  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if ('status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

export function getParamAsString(
  param: Array<string> | string | undefined
): string {
  return Array.isArray(param) ? param[0] : param ?? '';
}

export function getParamAsArray(
  param: Array<string> | string | undefined
): Array<string> {
  return Array.isArray(param) ? param : [];
}

/** Cart products */

const CART_KEY = 'product_cart';

export function getProductCartFromCookie(
  req?: IncomingMessage
): Array<ProductCart> {
  try {
    if (req) {
      if (!cookie.get(CART_KEY, req)) return [];
      const productsString = cookie.get(CART_KEY, req);
      return JSON.parse(productsString ?? '');
    }
    if (!cookie.get(CART_KEY)) return [];
    const productsString = cookie.get(CART_KEY);

    return JSON.parse(productsString ?? '');
  } catch (error) {
    console.error(error);
    cookie.remove(CART_KEY);
    return [];
  }
}

export function removeProductCartFromCookie(
  productToRemove: ProductCart
): void {
  const productList = getProductCartFromCookie();
  const updatedProductList = productList.filter(
    (product) => product.id !== productToRemove.id
  );
  cookie.set(CART_KEY, JSON.stringify(updatedProductList));
}

export function hasProductCartInCookie(currentProduct: ProductCart): boolean {
  const productList = getProductCartFromCookie();
  return productList.some((product) => product.id === currentProduct.id);
}

export function addProductCartToCookie(currentProduct: ProductCart) {
  const productList = getProductCartFromCookie();
  const updatedList = [...productList, currentProduct];
  cookie.set(CART_KEY, JSON.stringify(updatedList));
}

export function toggleProductCartInCookie(
  currentProduct: ProductCart
): boolean {
  const includesCurrentProduct = hasProductCartInCookie(currentProduct);
  if (includesCurrentProduct) {
    removeProductCartFromCookie(currentProduct);
  } else {
    addProductCartToCookie(currentProduct);
  }

  return hasProductCartInCookie(currentProduct);
}

/** Query **/

type ParamValue = string | (string | null)[];

type QueryType = { [key in string]?: ParamValue };

export function getParamAsString2(
  query: QueryType,
  key: string
): string | undefined {
  const queryValue = query[key];
  return typeof queryValue === 'string' ? queryValue : undefined;
}

export function getParamAsNumber(
  query: QueryType,
  key: string,
  defaultValue: number
): number {
  const queryValue = query[key];
  if (
    typeof queryValue === 'string' &&
    !Number.isNaN(parseInt(queryValue, 10))
  ) {
    return parseInt(queryValue, 10);
  }
  return defaultValue;
}

export function getParamAsStringArray(
  query: QueryType,
  key: string
): Array<string> {
  const queryValue = query[key];
  if (Array.isArray(queryValue)) {
    return queryValue.filter(isNotNullish);
  }
  if (typeof queryValue === 'string') {
    return [queryValue];
  }
  return [];
}

/** Get Category Url Alias From Router Query Slug **/

export function getCategoryUrlAliasFromRouterQuerySlug(
  routerQuerySlug: Array<string> | string | undefined,
  defaultValue: string
): string {
  if (routerQuerySlug) {
    if (Array.isArray(routerQuerySlug)) {
      return routerQuerySlug[0] ? routerQuerySlug[0] : defaultValue;
    }
    return routerQuerySlug;
  }
  return defaultValue;
}

export function getCategoryAliasFromRouterQuerySlug(
  routerQuerySlug: Array<string> | string | undefined
): string | undefined {
  if (routerQuerySlug) {
    if (Array.isArray(routerQuerySlug)) {
      return routerQuerySlug[0] ? routerQuerySlug[0] : undefined;
    }
    return routerQuerySlug;
  }
}

export function getProductAliasFromRouterQuerySlug(
  routerQuerySlug: Array<string> | string | undefined
): string | undefined {
  if (routerQuerySlug) {
    if (Array.isArray(routerQuerySlug)) {
      return routerQuerySlug[1] ? routerQuerySlug[1] : undefined;
    }
  }
}
