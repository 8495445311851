import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { cookie, createResourceLoader, ResourceType } from '@tager/web-core';

import { ProductFull } from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import { getProductByProductId } from '@/services/requests';
import { ProductCart } from '@/typings/common';
import { getProductCartFromCookie } from '@/utils/common';

const productsInCartLoader = createResourceLoader<Array<ProductFull>>([]);

type CartState = {
  productsInCart: ResourceType<Array<ProductFull>>;
  countProductsInCart: number;
  totalPriceProductsInCart: number;
};

const initialState: CartState = {
  productsInCart: productsInCartLoader.getInitialResource(),
  countProductsInCart: 0,
  totalPriceProductsInCart: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    productsInCartRequestPending(state) {
      state.productsInCart = productsInCartLoader.pending();
    },
    productsInCartRequestFulfilled(
      state,
      action: PayloadAction<Array<ProductFull>>
    ) {
      state.productsInCart = productsInCartLoader.fulfill(action.payload);
    },
    productsInCartRequestRejected(state) {
      state.productsInCart = productsInCartLoader.reject();
    },
    countProductsInCartRequest(state, action: PayloadAction<number>) {
      state.countProductsInCart = action.payload;
    },

    totalPriceProductsInCartRequest(state, action: PayloadAction<number>) {
      state.totalPriceProductsInCart = action.payload;
    },
  },
});

const { actions, reducer } = cartSlice;

export const {
  productsInCartRequestPending,
  productsInCartRequestFulfilled,
  productsInCartRequestRejected,
  countProductsInCartRequest,
  totalPriceProductsInCartRequest,
} = actions;

export default reducer;

export function getProductsInCartThunk(
  productsInCart: Array<ProductCart>
): AppThunk<Promise<Array<ProductFull>>> {
  return async (dispatch, getState) => {
    try {
      dispatch(productsInCartRequestPending());

      const responsesPromise = productsInCart.map((product) =>
        getProductByProductId(product.id)
      );

      const responses = await Promise.all(responsesPromise);
      const responseList = responses.map((response) => response.data);

      const currentPrice = responseList.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.price;
      }, 0);

      dispatch(totalPriceProductsInCartRequest(currentPrice));
      dispatch(productsInCartRequestFulfilled(responseList));

      return responseList;
    } catch (error) {
      console.error(error);
      dispatch(productsInCartRequestRejected());

      return [];
    }
  };
}

export function setCountProductsInCookieThunk(): AppThunk<Promise<number>> {
  return async (dispatch) => {
    try {
      dispatch(countProductsInCartRequest(getProductCartFromCookie().length));
      return getProductCartFromCookie().length;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };
}

export function removeProductFromCartThunk(
  productId: number
): AppThunk<Promise<Array<ProductFull>>> {
  return async (dispatch, getState) => {
    try {
      const productsInCart = selectProductInCartResource(getState());
      if (!productsInCart) return [];

      const newProductCart = productsInCart.data.filter(
        (productInCart) => productInCart.id !== productId
      );

      const currentPrice = newProductCart.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.price;
        },
        0
      );

      dispatch(totalPriceProductsInCartRequest(currentPrice));
      dispatch(productsInCartRequestFulfilled(newProductCart));

      return newProductCart;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
}

export function removeAllProductFromCartThunk(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      cookie.remove('product_cart');
      dispatch(totalPriceProductsInCartRequest(0));
      dispatch(productsInCartRequestFulfilled([]));
      dispatch(setCountProductsInCookieThunk);
    } catch (error) {
      console.error(error);
    }
  };
}

export function selectProductInCartResource(
  state: AppState
): ResourceType<Array<ProductFull>> {
  return state.cart.productsInCart;
}

export function selectCountProductsInCart(state: AppState): number {
  return state.cart.countProductsInCart;
}

export function selectTotalPriceProductsInCart(state: AppState): number {
  return state.cart.totalPriceProductsInCart;
}
