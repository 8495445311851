import React, { useMemo, useState } from 'react';

import {
  cookie,
  createContextHookWithProvider,
  Nullable,
} from '@tager/web-core';

export type SortContextType = {
  sortType: Nullable<string>;
  changeSortType: (newSortType: Nullable<string>) => void;
};

const [
  useSortContext,
  SortContextProvider,
] = createContextHookWithProvider<SortContextType>('SortContext');

export function useSort() {
  return useSortContext();
}

type Props = {
  initialSort: Nullable<string>;
  children?: React.ReactNode;
};

export function SortProvider({ initialSort, children }: Props) {
  const [sortType, setSortType] = useState(initialSort);
  function updateSortType(newSortType: Nullable<string>) {
    if (!newSortType) return null;
    cookie.set('sort', newSortType);
    setSortType(newSortType);
  }
  const context = useMemo<SortContextType>(
    () => ({ sortType, changeSortType: updateSortType }),
    [sortType]
  );

  return <SortContextProvider value={context}>{children}</SortContextProvider>;
}
