import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapEntry, Nullable } from '@tager/web-core';
import { getSeoParamsByAlias, SeoParamsType } from '@tager/web-modules';

import { AppState, AppThunk } from '@/store/store';
import { getCartSeoParams, getHomeSeoParams } from '@/services/requests';
import { SeoParams } from '@/typings/model';

interface SeoState {
  seoParamsMap: Record<string, SeoParamsType>;
  homSeoParamsMap: Nullable<SeoParams>;
  cart: Nullable<SeoParams>;
}

const initialState: SeoState = {
  seoParamsMap: {},
  homSeoParamsMap: null,
  cart: null,
};

const slice = createSlice({
  name: 'seoParams',
  initialState: initialState,
  reducers: {
    seoParamsAdded(
      state,
      action: PayloadAction<MapEntry<string, SeoParamsType>>
    ) {
      state.seoParamsMap[action.payload.key] = action.payload.value;
    },
    homeSeoParamsAdded(state, action: PayloadAction<SeoParams>) {
      state.homSeoParamsMap = action.payload;
    },
    cartSeoParamsAdded(state, action: PayloadAction<SeoParams>) {
      state.cart = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  seoParamsAdded,
  homeSeoParamsAdded,
  cartSeoParamsAdded,
} = slice.actions;

/** Thunks **/

export function getSeoParamsByAliasThunk(
  alias: string,
  options?: { shouldInvalidate?: boolean }
): AppThunk<Promise<Nullable<SeoParamsType>>> {
  return async (dispatch, getState) => {
    const seoParams = selectSeoParamsByPageAlias(getState(), alias);

    if (!options?.shouldInvalidate && seoParams) {
      return seoParams;
    }

    try {
      const response = await getSeoParamsByAlias(alias);

      dispatch(seoParamsAdded({ key: alias, value: response.data }));

      return response.data;
    } catch (error) {
      return null;
    }
  };
}

export function getHomeSeoParamsThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Nullable<SeoParams>>> {
  return async (dispatch, getState) => {
    const seoParams = selectHomeSeoParams(getState());

    if (!options?.shouldInvalidate && seoParams) {
      return seoParams;
    }

    try {
      const response = await getHomeSeoParams();

      dispatch(homeSeoParamsAdded(response.data));

      return response.data;
    } catch (error) {
      return null;
    }
  };
}

export function getCartSeoParamsThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Nullable<SeoParams>>> {
  return async (dispatch, getState) => {
    const seoParams = selectCartSeoParams(getState());

    if (!options?.shouldInvalidate && seoParams) {
      return seoParams;
    }

    try {
      const response = await getCartSeoParams();
      dispatch(cartSeoParamsAdded(response.data));
      return response.data;
    } catch (error) {
      return null;
    }
  };
}

/** Selectors **/

export function selectSeoParamsByPageAlias(
  state: AppState,
  alias: string
): SeoParamsType | undefined {
  return state.tager.seo.seoParamsMap[alias];
}

export function selectHomeSeoParams(state: AppState): Nullable<SeoParams> {
  return state.tager.seo.homSeoParamsMap;
}

export function selectCartSeoParams(state: AppState): Nullable<SeoParams> {
  return state.tager.seo.cart;
}
