import defaultDarkImageUrl from '@/assets/svg/categories/all-dark.svg';
import defaultImageUrl from '@/assets/svg/categories/all.svg';

export const defaultCategory = {
  id: 0,
  urlAlias: '/',
  name: 'All',
  icon: defaultDarkImageUrl,
  iconActive: defaultImageUrl,
};
