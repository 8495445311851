import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FilterType } from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import { getPlatformList } from '@/services/requests';

interface CategoryState {
  platformList: Array<FilterType>;
}

const initialState: CategoryState = {
  platformList: [],
};

const platformsSlice = createSlice({
  name: 'platforms',
  initialState,
  reducers: {
    platformListAdded(state, action: PayloadAction<Array<FilterType>>) {
      state.platformList = action.payload;
    },
  },
});

const { actions, reducer } = platformsSlice;

export const { platformListAdded } = actions;

export default reducer;

export function getPlatformListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<FilterType>>> {
  return async (dispatch, getState) => {
    try {
      const platforms = getPlatformsListData(getState());

      if (!options?.shouldInvalidate && platforms.length) {
        return platforms;
      }

      const response = await getPlatformList();

      dispatch(platformListAdded(response));
      return response;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
}

export function getPlatformsListData(state: AppState): Array<FilterType> {
  return state.platforms.platformList;
}
