import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  MapEntry,
  Nullable,
  ResourceType,
} from '@tager/web-core';

import { MetaPage, ProductFull, ProductShort } from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import {
  getProductList,
  getProductByCategoryAliasAndProductAlias,
  getPossibleGoodListByLiveSearch,
  getSortProductList,
} from '@/services/requests';
import { InitialValueProps } from '@/modules/Home/components/Filters';

const productListLoader = createResourceLoader<Array<ProductShort>>([]);
const productLoader = createResourceLoader<Nullable<ProductFull>>(null);
const possibleGoodListLoader = createResourceLoader<
  Array<{ id: number; name: string }>
>([]);

type CategoryState = {
  productListMap: ResourceType<Array<ProductShort>>;
  productMap: Record<string | number, ResourceType<Nullable<ProductFull>>>;
  metaPage: Nullable<MetaPage>;
  possibleGoodListMap: Record<
    string,
    ResourceType<Array<{ id: number; name: string }>>
  >;
};

const initialState: CategoryState = {
  productListMap: productListLoader.getInitialResource(),
  productMap: {},
  metaPage: null,
  possibleGoodListMap: {},
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    productListRequestPending(state) {
      state.productListMap = productListLoader.pending();
    },
    productListRequestFulfilled(
      state,
      action: PayloadAction<Array<ProductShort>>
    ) {
      state.productListMap = productListLoader.fulfill(action.payload);
    },
    productListRequestRejected(state) {
      state.productListMap = productListLoader.reject();
    },

    productRequestPending(
      state,
      action: PayloadAction<{ key: string | number }>
    ) {
      state.productMap[action.payload.key] = productLoader.pending();
    },
    productRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string | number, ProductFull>>
    ) {
      state.productMap[action.payload.key] = productLoader.fulfill(
        action.payload.value
      );
    },
    productRequestRejected(
      state,
      action: PayloadAction<{ key: string | number }>
    ) {
      state.productMap[action.payload.key] = productLoader.reject();
    },

    addedMetaPage(state, action: PayloadAction<MetaPage>) {
      state.metaPage = action.payload;
    },

    possibleGoodListRequestPending(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.possibleGoodListMap[
        action.payload.key
      ] = possibleGoodListLoader.pending();
    },
    possibleGoodListRequestFulfilled(
      state,
      action: PayloadAction<
        MapEntry<string, Array<{ id: number; name: string }>>
      >
    ) {
      state.possibleGoodListMap[
        action.payload.key
      ] = possibleGoodListLoader.fulfill(action.payload.value);
    },
    possibleGoodListRequestRejected(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.possibleGoodListMap[
        action.payload.key
      ] = possibleGoodListLoader.reject();
    },
  },
});

const { actions, reducer } = productSlice;

export const {
  productListRequestPending,
  productListRequestFulfilled,
  productListRequestRejected,
  productRequestPending,
  productRequestFulfilled,
  productRequestRejected,
  addedMetaPage,
  possibleGoodListRequestPending,
  possibleGoodListRequestFulfilled,
  possibleGoodListRequestRejected,
} = actions;

export default reducer;

export function getProductListThunk(
  itemsPerPage: number,
  page: string | undefined,
  sort: string,
  categoryId: string | number,
  searchQuery: string,
  values?: InitialValueProps | undefined
): AppThunk<Promise<Array<ProductShort>>> {
  return async (dispatch, getState) => {
    try {
      dispatch(productListRequestPending());

      const response = values
        ? await getProductList(
            itemsPerPage,
            page ? page : '1',
            sort,
            categoryId,
            {
              features: values.features,
              subcategories: values.subcategories,
              platforms: values.platforms,
            },
            {
              videoFaceAr: values.videoFaceAr,
              videoPostProcessing: values.videoPostProcessing,
            },
            searchQuery
          )
        : await getSortProductList(
            itemsPerPage,
            page ? page : '1',
            sort,
            categoryId
          );

      dispatch(addedMetaPage(response.meta));
      dispatch(productListRequestFulfilled(response.data));

      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(productListRequestRejected());
      return [];
    }
  };
}

export function getPossibleGoodListThunk(
  query: Nullable<string>,
  categoryId: string | number,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Array<{ id: number; name: string }>>> {
  return async (dispatch, getState) => {
    try {
      const product = selectPossibleGoodListByCategoryIdAndQuery(
        getState(),
        query,
        categoryId
      );
      if (!options?.shouldInvalidate && product) {
        return product.data;
      }

      dispatch(
        possibleGoodListRequestPending({ key: query + '-' + categoryId })
      );

      const response = await getPossibleGoodListByLiveSearch(query, categoryId);

      dispatch(
        possibleGoodListRequestFulfilled({
          key: query + '-' + categoryId,
          value: response.data,
        })
      );
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(
        possibleGoodListRequestRejected({ key: query + '-' + categoryId })
      );
      return [];
    }
  };
}

export function getProductByCategoryAliasAndProductAliasThunk(
  categoryAlias: string,
  productUrlAlias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<ProductFull>>> {
  return async (dispatch, getState) => {
    try {
      const product = selectProductByCategoryAliasAndProductAlias(
        getState(),
        categoryAlias,
        productUrlAlias
      );

      if (!options?.shouldInvalidate && product) {
        return product.data;
      }

      dispatch(
        productRequestPending({ key: categoryAlias + '-' + productUrlAlias })
      );

      const response = await getProductByCategoryAliasAndProductAlias(
        categoryAlias,
        productUrlAlias
      );

      dispatch(
        productRequestFulfilled({
          key: categoryAlias + '-' + productUrlAlias,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(
        productRequestRejected({ key: categoryAlias + '-' + productUrlAlias })
      );
      return null;
    }
  };
}

export function selectProductList(
  state: AppState
): ResourceType<Array<ProductShort>> {
  return state.product.productListMap;
}

export function selectMetaPage(state: AppState): Nullable<MetaPage> {
  return state.product.metaPage;
}

export function selectProductByCategoryAliasAndProductAlias(
  state: AppState,
  categoryAlias: string,
  productUrlAlias: string
): ResourceType<Nullable<ProductFull>> {
  return state.product.productMap[categoryAlias + '-' + productUrlAlias];
}

export function selectPossibleGoodListByCategoryIdAndQuery(
  state: AppState,
  query: Nullable<string>,
  categoryId: string | number
): ResourceType<Array<{ id: number; name: string }>> {
  return state.product.possibleGoodListMap[query + '-' + categoryId];
}
