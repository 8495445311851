import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapEntry, Nullable } from '@tager/web-core';

import { CategoryShort, CategoryFull } from '@/typings/model';
import { AppState, AppThunk } from '@/store/store';
import { getCategoryByAlias, getCategoryList } from '@/services/requests';

interface CategoryState {
  categoryListMap: Array<CategoryShort>;
  categoryByAliasMap: Record<string, CategoryFull>;
}

const initialState: CategoryState = {
  categoryListMap: [],
  categoryByAliasMap: {},
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    categoryListAdded(state, action: PayloadAction<Array<CategoryShort>>) {
      state.categoryListMap = action.payload;
    },
    categoryByAlias(
      state,
      action: PayloadAction<MapEntry<string, CategoryFull>>
    ) {
      state.categoryByAliasMap[action.payload.key] = action.payload.value;
    },
  },
});

const { actions, reducer } = categorySlice;

export const { categoryListAdded, categoryByAlias } = actions;

export default reducer;

export function getCategoryListThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Array<CategoryShort>>> {
  return async (dispatch, getState) => {
    try {
      const category = getCategoryListData(getState());

      if (!options?.shouldInvalidate && category.length) {
        return category;
      }

      const response = await getCategoryList();

      dispatch(categoryListAdded(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
}

export function getCategoryByAliasThunk(
  categoryAlias: string,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<CategoryFull>>> {
  return async (dispatch, getState) => {
    try {
      if (!categoryAlias) return null;
      const category = selectCategoryByAlias(getState(), categoryAlias);

      if (!options?.shouldInvalidate && category) {
        return category;
      }

      const response = await getCategoryByAlias(categoryAlias);

      dispatch(categoryByAlias({ key: categoryAlias, value: response.data }));

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
}

export function getCategoryListData(state: AppState): Array<CategoryShort> {
  return state.category.categoryListMap;
}

export function selectCategoryByAlias(
  state: AppState,
  categoryAlias: string
): CategoryFull {
  return state.category.categoryByAliasMap[categoryAlias];
}
