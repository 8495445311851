import { Nullable, request, ResponseBody } from '@tager/web-core';
import { FileType } from '@tager/admin-services';

import {
  CategoryByUrlAliasModel,
  CategoryFull,
  CategoryModel,
  CategoryShort,
  LiveSearchModel,
  MetaModel,
  PlatformModel,
  ProductFull,
  ProductFullModel,
  ProductModel,
  ProductShort,
  ResponseBodyWithMeta,
  SeoParams,
} from '@/typings/model';

export type SuccessData = { success: boolean };

export function getExampleList() {
  return request.get({ path: '/tager/example' });
}

export function createExample() {
  return request.post({ path: '/tager/example', body: { name: 'example' } });
}

export function getHomeSeoParams(): Promise<ResponseBody<SeoParams>> {
  return request.get({ path: `/tager/seo/template/home` });
}

export function getCartSeoParams(): Promise<ResponseBody<SeoParams>> {
  return request.get({ path: '/tager/seo/template/cart' });
}

export function getCategoryList(): Promise<ResponseBody<Array<CategoryShort>>> {
  return request.get({ path: '/categories' });
}

export function getCategoryByAlias(
  categoryAlias: string
): Promise<ResponseBody<CategoryFull>> {
  return request.get({ path: `/categories/${categoryAlias}` });
}

export function getSortProductList(
  pageSize: number,
  pageNumber: string,
  sort: string,
  categoryId: number | string
): Promise<ResponseBodyWithMeta<Array<ProductShort>>> {
  return request.get({
    path: `/products`,
    params: {
      pageSize,
      pageNumber,
      sort,
      category: categoryId,
    },
  });
}

export type FilterPayload = {
  features: Array<string>;
  subcategories: Array<string>;
  platforms: Array<string>;
};

export type VideoEditPayload = {
  videoFaceAr: boolean;
  videoPostProcessing: boolean;
};

export function getProductList(
  pageSize: number,
  pageNumber: string,
  sort: string,
  categoryId: string | number,
  filterPayload: FilterPayload,
  videoEditorPayload: VideoEditPayload,
  searchQuery?: string
): Promise<ResponseBodyWithMeta<Array<ProductShort>>> {
  function getRequestParams(
    filterPayload: { [key: string]: Array<string> },
    videoEditorPayload: VideoEditPayload
  ) {
    const param = new URLSearchParams();
    param.append('pageSize', pageSize.toString());
    param.append('pageNumber', pageNumber);
    param.append('sort', sort);
    param.append('category', categoryId.toString());
    Object.keys(filterPayload).forEach((key) => {
      if (Array.isArray(filterPayload[key])) {
        filterPayload[key].forEach((filter) => {
          param.append(key + '[]', filter);
        });
      }
    });

    if (videoEditorPayload.videoFaceAr) {
      param.append('videoFaceAr', '1');
    }
    if (videoEditorPayload.videoPostProcessing) {
      param.append('videoPostProcessing', '1');
    }
    if (searchQuery) {
      param.append('query', searchQuery);
    }

    return param;
  }

  return request.get({
    path: `/products`,
    params: getRequestParams(filterPayload, videoEditorPayload),
  });
}

export type SearchFilterType = {
  platforms: Array<string>;
};

export function getProductListByCategoryId(
  categoryId: number
): Promise<ResponseBodyWithMeta<Array<ProductShort>>> {
  return request.get({ path: `/products`, params: { category: categoryId } });
}

export function getProductByCategoryAliasAndProductAlias(
  categoryAlias: string,
  productUrlAlias: string
): Promise<ResponseBodyWithMeta<ProductFull>> {
  return request.get({
    path: `/products/view`,
    params: { category: categoryAlias, urlAlias: productUrlAlias },
  });
}

export function getProductByProductId(
  productId: number
): Promise<ResponseBody<ProductFull>> {
  return request.get({
    path: `/products/${productId}`,
  });
}

export function getPossibleGoodListByLiveSearch(
  query: Nullable<string>,
  categoryId: string | number
): Promise<ResponseBody<Array<{ id: number; name: string }>>> {
  return request.get({
    path: `/products/live-search`,
    params: { query, category: categoryId },
  });
}

export function getPlatformList(): Promise<Array<FileType>> {
  return request.get({ path: `/platforms` });
}

export type OrderPayload = {
  name: string;
  surname: string;
  email: string;
  company: string;
};

export function submitOrder(
  payload: OrderPayload,
  products: Array<number>,
  monthlyActiveUsers: Nullable<string>
): Promise<ResponseBody<SuccessData>> {
  return request.post({
    path: `/checkout`,
    body: { ...payload, products, monthlyActiveUsers },
  });
}

/** Live Search **/

export function fetchLiveSearch({
  query,
  category,
  signal,
}: {
  query: string;
  category?: number;
  signal: AbortSignal;
}): Promise<ResponseBody<Array<LiveSearchModel>>> {
  const params: { query: string; category?: number } = { query };
  if (category) {
    params.category = category;
  }
  return request.get({
    path: '/products/live-search',
    params,
    fetchOptions: { signal },
  });
}

/** Platforms **/

export function fetchPlatforms(): Promise<Array<PlatformModel>> {
  return request.get({ path: '/platforms' });
}

/** Categories **/

export function fetchCategories(): Promise<ResponseBody<Array<CategoryModel>>> {
  return request.get({ path: '/categories' });
}

export function fetchCategoryByUrlAlias(
  categoryAlias: string
): Promise<ResponseBody<CategoryByUrlAliasModel>> {
  return request.get({ path: `/categories/${categoryAlias}` });
}

/** Products **/

export function fetchProducts(obj: {
  searchQuery?: string;
  pageSize?: number;
  pageNumber?: number;
  categoryId?: number;
  categoryAlias?: string;
  videoFaceAr?: string;
  videoPostProcessing?: string;
  platforms?: Array<string>;
  subcategories?: Array<string>;
  features?: Array<string>;
}): Promise<ResponseBody<Array<ProductModel>, MetaModel>> {
  const params = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(`${key}[]`, value));
    }
    if (typeof value === 'number') {
      params.append(key, String(value));
    }
    if (typeof value === 'string') {
      if (key === 'searchQuery') {
        params.append('query', value);
      } else {
        params.append(key, value);
      }
    }
  });

  return request.get({
    path: '/products',
    params,
  });
}

/** Product **/

export function fetchProductByCategoryAliasAndProductAlias({
  categoryAlias,
  productAlias,
}: {
  categoryAlias: string;
  productAlias: string;
}): Promise<ResponseBody<ProductFullModel>> {
  return request.get({
    path: `/products/view`,
    params: { category: categoryAlias, urlAlias: productAlias },
  });
}
